import React, { useMemo } from 'react';

import type { CommunityMember } from '@zealy/queries';
import type { GetQuestboardOutput, QuestContributorFilters } from '@zealy/utils';
import { useAuthenticatedUser, useCurrentSprint, useQuest, useQuestBoard } from '@zealy/queries';

import { useQuestboardContext } from './QuestboardContext';

const questFilters = {
  byId: (allowedIds: string[]) => (quest: GetQuestboardOutput[number]['quests'][number]) =>
    allowedIds.includes(quest.id),
};

export const useFilteredQuestboard = ({
  filterByCategories = true,
  customFilters,
  enabled = true,
}: {
  filterByCategories?: boolean;
  customFilters?: QuestContributorFilters[];
  enabled?: boolean;
} = {}): {
  modules: GetQuestboardOutput;
  totalQuestCount: number;
  isLoading: boolean;
} => {
  const user = useAuthenticatedUser<CommunityMember>();
  const { selectedCategories, sprintView, filters } = useQuestboardContext();
  const questboard = useQuestBoard(customFilters ?? filters, {
    enabled: enabled && user.data?.role !== 'banned',
  });
  const { data: sprint } = useCurrentSprint();

  const questIds =
    sprintView && sprint && !!sprint?.includedQuestIds?.length
      ? sprint?.includedQuestIds
      : undefined;

  return React.useMemo(() => {
    if (questboard.isLoading)
      return { isLoading: true, modules: [] as GetQuestboardOutput, totalQuestCount: 0 };

    return (questboard.data ?? []).reduce(
      (acc, curr) => {
        const quests = questIds ? curr.quests.filter(questFilters.byId(questIds)) : curr.quests;

        const currentModule = {
          ...curr,
          quests,
        };

        const shouldIncludeModule =
          quests.length &&
          (!filterByCategories ||
            !selectedCategories?.length ||
            selectedCategories.includes(curr.id));

        const modules = shouldIncludeModule ? [...acc.modules, currentModule] : acc.modules;

        return {
          totalQuestCount: acc.totalQuestCount + curr.totalQuestCount,
          modules,
          isLoading: false,
        };
      },
      {
        modules: [] as GetQuestboardOutput,
        totalQuestCount: 0,
        isLoading: false,
      },
    );
  }, [questboard.isLoading, questboard.data, questIds, filterByCategories, selectedCategories]);
};

type Quest = GetQuestboardOutput[number]['quests'][number];

export const useQuestNavigation = (
  questId?: string,
  onSuccess = false,
): {
  prevQuest?: Quest;
  nextQuest?: Quest;
} => {
  const { modules } = useFilteredQuestboard({
    customFilters: onSuccess ? ['available', 'locked'] : ['available'],
    enabled: !!questId,
  });

  const availableQuests = useMemo(() => {
    const quests = modules?.flatMap(category => category.quests) ?? [];

    if (!onSuccess) return quests;
    /**
     * Only include quests that are depending on current quest
     */
    return quests.filter(({ conditions }) => {
      const locked = conditions?.filter(condition => !condition.fulfilled);
      if (locked.length > 1) return false;
      return locked[0]?.type === 'quest' && locked[0]?.value === questId;
    });
  }, [modules, questId, onSuccess]);

  const index = availableQuests.findIndex(quest => quest.id === questId);
  const prevQuest = availableQuests[index - 1];
  const nextQuest = availableQuests[index + 1];

  useQuest(nextQuest?.id);

  return { nextQuest, prevQuest };
};
