'use client';

import { useLocalStorageValue, useSessionStorageValue } from '@react-hookz/web';
import { useEffect } from 'react';
import { useParams, usePathname } from 'next/navigation';

import type { QuestContributorFilters } from '@zealy/utils';

import { createContext } from '#context/createContext';

export interface QuestboardContext {
  selectedCategories: string[];
  setSelectedCategories: (categoryIds: string[]) => void;

  gridView: boolean;
  setGridView: (enabled: boolean) => void;

  sprintView: boolean;
  setSprintView: (enabled: boolean) => void;

  filters: QuestContributorFilters[];
  toggleFilter: (filter: QuestContributorFilters) => void;
  clearFilters: () => void;
}

export const DEFAULT_FILTERS = [
  'locked',
  'available',
  'inCooldown',
  'inReview',
] as QuestContributorFilters[];

export const ALL_FILTERS = [...DEFAULT_FILTERS, 'completed'] as QuestContributorFilters[];

export const [QuestboardContextProvider, useQuestboardContext] = createContext<QuestboardContext>({
  name: 'QuestboardContext',
  strict: true,
  hookName: 'useQuestboardContext',
  providerName: 'QuestboardProvider',
  defaultValue: {
    selectedCategories: [],
    setSelectedCategories: () => {},
    gridView: false,
    setGridView: () => {},
    sprintView: false,
    setSprintView: () => {},
    filters: [],
    toggleFilter: () => {},
    clearFilters: () => {},
  },
});

export const QuestboardWrapper = ({ children }: { children: React.ReactNode }) => {
  const { subdomain } = useParams<{ subdomain: string }>();
  const pathname = usePathname();
  const { value: filters = DEFAULT_FILTERS, set: setFilters } = useLocalStorageValue<
    QuestContributorFilters[]
  >('questboard-filters', {
    defaultValue: DEFAULT_FILTERS,
  });
  const { value: selectedCategories, set: setSelectedCategories } = useSessionStorageValue<
    string[]
  >('selectedCategories', {
    defaultValue: [],
  });
  const { value: sprintView, set: setSprintView } = useSessionStorageValue<boolean>(
    `sprintView:${subdomain}`,
  );
  const { value: gridView = false, set: setGridView } = useLocalStorageValue<boolean>('gridView', {
    defaultValue: false,
  });

  useEffect(() => {
    if (typeof sprintView !== 'boolean') setSprintView(pathname.includes('sprints'));
  }, [pathname, setSprintView, sprintView]);

  const toggleFilter = (filter: QuestContributorFilters) => {
    if (filters.includes(filter)) {
      setFilters(filters.filter(f => f !== filter));
    } else {
      setFilters([...filters, filter]);
    }
  };

  return (
    <QuestboardContextProvider
      value={{
        selectedCategories: selectedCategories ?? [],
        setSelectedCategories,
        gridView,
        setGridView,
        filters,
        toggleFilter,
        clearFilters: () => setFilters(ALL_FILTERS),
        sprintView: !!sprintView,
        setSprintView,
      }}
    >
      {children}
    </QuestboardContextProvider>
  );
};
