'use client';

import React from 'react';

import { Tag } from '@zealy/design-system';

import { useFilteredQuestboard } from '../Board.hooks';
import { useQuestboardContext } from '../QuestboardContext';

export const CategoryFilters = () => {
  const { modules } = useFilteredQuestboard({ filterByCategories: false });
  const { selectedCategories, setSelectedCategories } = useQuestboardContext();

  return (
    <>
      {modules?.map(category => {
        const isSelected = selectedCategories.includes(category.id);
        return (
          <Tag
            key={category.id}
            onClick={() =>
              setSelectedCategories(
                isSelected
                  ? selectedCategories.filter(c => c !== category.id)
                  : [...selectedCategories, category.id],
              )
            }
            selected={isSelected}
            size="sm"
          >
            {category.title}
          </Tag>
        );
      })}
    </>
  );
};
